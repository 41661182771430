import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import {
  Section,
  SectionWrapper,
  SassyText,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { Button } from "../../components/core/Button";
import { MetaComponent } from "../../components/MetaComponent";
import { OnDesktop } from '../../components/DesktopMobileComps';
import { getQueryParams } from "../../utils/queryParamUtils";
import { getPageName } from "../../utils/getCurrentPageName";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, HeroSectionContainer, HeroSectionData, QuoteModalPlaceholder, ModalContainer, HeroSectionHeading } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import { useCampaignLPStates } from "../../hooks/useCampaignLPStates";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});
const SelfServe = loadable(() => import("../../components/SelfServe"))

const PerilsSection = loadable(() => import("../../components/campaigns/google1/PerilsSection"))
const StepsToProtectBusiness = loadable(() => import(/* webpackPrefetch: true */ "../../components/StepsToProtectBusiness/StepsToProtectBusinessGLPs"))
const ExtraBenefits = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/ExtraBenefits"))
const QuotesFromCompanies = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/QuotesFromCompanies"))
const ReviewSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google1/ReviewSection"))
const WhyChooseVerak = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/WhyChooseVerak"))
const FooterSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google1/FooterSection"))

const IndexPage = () => {
  const mixpanel = useMixpanel();

  const {
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    hideMinPremium,
    setHideMinPremium,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()

  const handleGetQuoteClick = (e, ctaNumber ="") => {
    mixpanel.track(`Clicked 'Get a Quote'- ${ctaNumber}(${pageName})`);
    scrollToLeadForm()
  };

  const hidePremium = () => {
    setHideMinPremium(true)
  }

  const heroSectionDefaultComp = (
    <>
      <StyledHeroSectionHeading>
        Insure your business property <OnDesktop style={{height: "0"}}><br/></OnDesktop>
        {!hideMinPremium && <>
          starting at{" "}
          <SassyText>just ₹8 per day!</SassyText>
        </>}
      </StyledHeroSectionHeading>
      {!hideQuoteCTAs && <OnDesktop><Button style={{padding: "12px 24px"}} label="GET A QUOTE" onClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} /></OnDesktop>}
      <HeroSectionSubHeading>
          Protection from 15+ threats
      </HeroSectionSubHeading>
      <PerilsSection />
    </>
  )

  return (
    <>
      <MetaComponent loadYellowAiScript={false} loadFbPixelScript={false} />
      <GLPNavbar 
        hideQuoteCTA={hideQuoteCTAs || isSelfServe} 
        showCallHelplineCTA={isSelfServe} 
        hidePremium={hidePremium} 
        handleGetQuoteClick={scrollToLeadForm} 
        handleCallScheduleClick={handleCallScheduleClick}
      />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff" style={{ display: isSelfServe ? "none" : "block", marginTop: '4rem', position: "relative"}}>
          <HeroBg />
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: hideHeroSection ? "0" : "6px"}} desktopStyles={{paddingTop: "40px"}}>
            <HeroSectionData>
              <StyledHeroSectionContainer hideHeroSection={hideHeroSection}>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </StyledHeroSectionContainer>
              <ModalContainer hideHeroSection={hideHeroSection} ref={leadformWrapperRef}>
                <LeadForm 
                  ref={leadformRef}
                  formType="google" 
                  hideMinPremium={hidePremium} 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  renderAsPopup={false} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />} 
                  setHeroSectionScreen={setScreen}
                />
              </ModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
      {!isSelfServe ? <StepsToProtectBusiness /> : null}
      <ExtraBenefits />
      <QuotesFromCompanies title="Get Quotes from Reputed Insurers" mobileStyles={{}} desktopStyles={{}} />
      <ReviewSection hideQuoteCTA={hideQuoteCTAs} handleGetQuoteClick={(e, ctaNumber=2) => handleGetQuoteClick(e, ctaNumber)} />
      <WhyChooseVerak title="Why choose Verak for your insurance needs?" style={{background: "#fff"}} hideQuoteCTA={hideQuoteCTAs} handleGetQuoteClick={(e, ctaNumber=3) => handleGetQuoteClick(e, ctaNumber)} />
      <FooterSection hideQuoteCTA={hideQuoteCTAs} handleGetQuoteClick={(e, ctaNumber=4) => handleGetQuoteClick(e, ctaNumber)} />

      <Footer style={{background: "#fff"}} />
    </>
  );
};

const StyledHeroSectionContainer = styled(HeroSectionContainer)`
  @media (max-width: 768px) {
    display: ${p => p.hideHeroSection ? "none" : "block"};
  }
`

const StyledHeroSectionHeading = styled(HeroSectionHeading)`
  font-size: 40px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 0;
    text-align: left;
  }
`;

const HeroSectionSubHeading = styled.h4`
  margin-top: 60px; 
  opacity: 0.7;
  font-size: 26px;
  color: #333333;
  font-weight: bold;
  @media (max-width: 768px) {
    margin-top: 4px;
    font-size: 14px;
    text-align: left;
  }
`

export default IndexPage;
